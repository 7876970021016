@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 200;
	src: url('../fonts/poppins-v21-latin-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/poppins-v21-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/poppins-v21-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 800;
	src: url('../fonts/poppins-v21-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

:root {
	--white:#FFFFFF;
	--black:#4B4B4B;
	--dark-blue:#567196;
	--light-blue:#C0F2FF;
	--dark-grey:#6B717C;
	--medium-grey:#CCCCCC;
	--light-grey:#E9E8E7;
	--dark-green:#339948;
	--light-green:#A6DF91;
	--dark-red:#ED7157;
	--light-red:#FCC3B9;
	--orange:#F9B06E;
	--yellow:#FCEC6F;

	--bigesttext:80px;
	--bigtext:50px;
	--bigtitle:24px;
	--title:18px;
	--subtitle:15px;	/* and button */
	--text:13px;
	--tinytext:7px;
}

*
{
	box-sizing: border-box;
	scroll-behavior: smooth;
	-webkit-tap-highlight-color: transparent;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.cordova 
{
	display:none;
}
.app_cordova .cordova
{
	display:block;
}  
.app_cordova .no_cordova 
{
	display:none;
}


img 
{
	width:100%;
	display:block;
}

html 
{
	font-family: "Poppins", sans-serif;
	font-size:var(--text);
	-webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
}
body {
	margin: 0 auto;
	max-width:800px;
	height: 100vh;
	display: flex;
	background-color: var(--white);
	line-height:1.2;
}
input 
{
	font-family: "Poppins", sans-serif;
}
input:focus 
{
	outline:2px solid var(--dark-blue) !important;
}
h1,h2,h3,h4
{
	margin:0;
}
h1 
{
	font-weight:600;
	font-size:var(--title);
}
h2 
{
	font-weight:600;
	font-size:var(--subtitle);
}
h3{
	font-weight:400;
	font-size:var(--subtitle);
}
h4 
{
	font-weight:400;
	font-size:var(--bigtitle);
}

.app-content
{
	background:var(--white);
	width: 100%;
	height: 100%;
	apadding: 20px;
	box-sizing: border-box;
	overflow-y: auto;
	position: absolute;
	top: 0;
	left: 0;
	transform-origin: top left;
}

/* General classes */
.hide
{
	 display:none !important;
}

section 
{
	display:none;
	min-height:100%;
	flex-direction:column;
	/*background:var(--light-blue);*/
}
section.active 
{
	display:flex;
}
.padding 
{
	padding:10px;
	--padding-top:18px;
	--padding-bottom:20px;
}

.link_default 
{
	display:block;
	color:var(--dark-blue);
	text-align: center;
	text-transform: uppercase;
	text-decoration:none;

}
.btn_default
{
	font-size:var(--subtitle);
	display:block;
	background:var(--dark-blue);
	border:2px solid var(--dark-blue);
	color:var(--white);
	padding:20px 10px;
	text-align: center;
	border-radius:15px;
	text-transform: uppercase;
	text-decoration:none;
	width:100%;
}
.btn_white 
{
	background:var(--white);
	color:var(--dark-blue);
	display:flex;
	align-items: center;
	justify-content: center;
}
.btn_white img 
{
	margin:0 5px 0 10px;
	width:20px;
}
.btn_white img.left 
{
	margin:0 15px 0 5px;
}
.btn_warning 
{
	background:var(--dark-red);
	border-color:var(--dark-red);
}
.btn_small 
{
	border:2px solid var(--dark-blue);
	padding:7px 10px;
	color:var(--dark-blue);
	text-decoration: none;
	display: flex;
    flex-wrap: wrap;
    border-radius: 25px;
    margin-top: 15px;
    align-items: center;
    justify-content: center;
    gap: 2px;
}
.btn_small img 
{
	margin-left:5px;
	width:15px;
}
.btn_small .value 
{
	font-weight:600;
}
.link_default
{
	display:block;
	color:var(--dark-blue);
	padding:5px 10px;
	text-align: center;
	text-decoration:none;
	width:100%;
}
.link_warning 
{
	color:var(--dark-red);
	border-color:var(--dark-red);
}
/* SPLASH */
.splash 
{
	background:var(--light-blue);
	align-items:center;
	justify-content:center;
}
.splash img
{
	width:80%;
	margin-bottom:10%;
}

/* TEST */

.restart_reward 
{
	padding-left:10px;
}
.custom_progress
{
	width:100%;
	height:12px;
	border-radius:6px;
	background:var(--light-blue);
	position:relative;
	overflow: hidden;;
}
.custom_progress_value
{
	position:absolute;
	content:"";
	width:50%;
	top:0;
	left:0;
	height:100%;
	border-radius:6px;
	background:var(--dark-blue);
}

.test_header_top
{
	display:flex;
	width:100%;
	height:fit-content;
	gap:10px;
	align-items:start;
	margin-bottom:10px;
}
.test_progress 
{
	width:100%;
	height:20px;
}
.test_close 
{
	width:50px;
}
.test_close img
{
	width:15px;
	height:15px;
}
.test_info
{
	flex:1;
	text-align:center;
}
.test_coins 
{
	display:flex;
	align-items:center;
	gap:5px;
	justify-content:end;
	width:50px;
}
.test_coins img 
{
	width:20px;
	height:20px;
}
.test_name 
{
	font-size:var(--subtitle);
	color:var(--dark-blue);
	font-weight:400;
}
.test_header 
{
	padding:20px;
}
.test_content 
{
	flex-grow:1;
	padding:0 20px 20px 20px;
	height:100%;

	/* rev 1 */
	display:flex;
	flex-direction: column;
}
.question_type 
{
	display: flex;
    flex-direction: column;
    align-items: center;
	/*height:100%;*/

	/* rev 1 */
	flex:1;
}
.question_info 
{
	text-transform:uppercase;
	text-align: center;
	color:var(--dark-blue);
	font-size:var(--subtitle);
	margin-bottom:10px;
}
.question_text 
{
	font-size:var(--title);
	font-weight:600;
	margin-bottom:20px;
	text-align:center;
	line-height:1.3;
}
.question_content 
{
	flex:1;
	width:100%;
	/* rev 1 */
	display:flex;
	align-items: center; /* stretch */
}
.test_footer
{
	padding:5px 20px 40px 20px;
	border-top:2px solid transparent;
	border-radius:20px 20px 0 0;
}
.test_footer_header 
{
	visibility:hidden;
	display:flex;
	height:30px;
	display:flex;
	align-items:center;
	margin-bottom:10px;
}
.test_footer_header_info 
{
	flex:1;
}
.btn_check_test 
{
	display:block;
	padding:25px 20px;
	text-align:center;
	background:var(--light-grey);
	text-decoration:none;
	font-size:var(--subtitle);
	line-height:1;
	color:inherit;
	border-radius:10px;
	text-transform:uppercase;
	transition:all 0.25s; 
}
.test_footer.ready .btn_check_test 
{
	background:var(--dark-blue);
	color:var(--white);
}
.test_footer.disabled
{

}
#test.correct .test_footer 
{
	background:var(--light-green);
	border-color:var(--dark-green);
}
#test.incorrect .test_footer
{
	background:var(--light-red);
	border-color:var(--dark-red);
} 

#test.correct .btn_check_test 
{
	background:var(--dark-green);
}
#test.incorrect .btn_check_test 
{
	background:var(--dark-red);
}
#test.correct .test_footer_header
{
	visibility:visible;
	color:var(--dark-green);
	fill:var(--dark-green);
}
#test.incorrect .test_footer_header
{
	visibility:visible;
	color:var(--dark-red);
	fill:var(--dark-red);
}
.test_result 
{
	display:none;
	align-items:center;
	gap:5px;
}
.test_result img 
{
	width:24px;
}
#test.correct .text_ok
{
	display:flex;
}
#test.incorrect .text_ko
{
	display:flex;
}

.btn_text_continue
{
	display:none;
}
#test.correct .btn_text_check, #test.incorrect .btn_text_check 
{
	display:none;
}
#test.correct .btn_text_continue , #test.incorrect .btn_text_continue 
{
	display:block;
}

.restart_section .test_content 
{
	padding-top:40px;
	align-items: center;
	ajustify-content: center;
	display:flex;
	flex-direction: column;
	gap:50px;
}
.restart_section 
{
	text-align: center;
}

/* ---- REGISTER -----------------*/
.register_top 
{
	display:flex;
	align-items: center;
	gap:10px;
}
.register_top progress 
{
	flex:1;
}
.register_top progress[value] 
{
	--color: var(--dark-blue);
	--background: var(--light-blue);
  
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	height:12px;
	border-radius: 10em;
	background: transparent;
  }
.register_top progress[value]::-webkit-progress-bar {
	border-radius: 10em;
	background: var(--background);
  }
.register_top  progress[value]::-webkit-progress-value {
	border-radius: 10em;
	background: var(--color);
  }
.register_top progress[value]::-moz-progress-bar {
	border-radius: 10em;
	background: var(--color);
  }
.register_body 
{
	flex:1;
	padding-top:50px;
	text-align: center;
	display:flex;
	flex-direction: column;
	align-items: center;
}
.register_body label 
{
	font-size:var(--subtitle);
	margin-bottom:30px;
	display:block;
	line-height:1.4;
}
.register_body .register_start_info 
{
	font-weight: 400;
	font-size:var(--bigtitle);
	margin-bottom:70px;
}
.register_body .register_start_title
{
	font-weight: 400;
	font-size:var(--title);
	margin-bottom:50px;
}
.register_img 
{
	width:40%;
	margin-bottom:50px;
}
.register_values
{
	display:flex;
	width:100%;
	gap:10px;
	flex-wrap: wrap;
}

.input_container 
{
	position:relative;
}
.input_container.last 
{
	margin-bottom:20px;
}
.register_select 
{
	font-size:var(--subtitle);
	background:var(--light-blue);
	border-radius:10px;
	padding:20px 10px;
	border:none;
	atext-align: center;
	aheight:50px;
}
.input_placeholder 
{
	position:absolute;
	top:calc(50% - 8px);
	left:18px;
	font-size:var(--subtitle);
	color:var(--dark-grey);
	display: flex;
}
.input_placeholder svg 
{
	height:15px;
	fill:var(--dark-grey);
	margin-right:18px;
}
.input_container input:not(:placeholder-shown) + .input_placeholder {
    opacity: 0;
  }

input.register_select 
{
	width:100%;
}
input.register_select.left 
{
	text-align:left;
	padding:20px 18px;	
}

.col 
{
	flex:1;
}
.register_options 
{
	width:100%;
	margin-bottom:40px;
}
.register_option 
{
	background:var(--light-blue);
	display:block;
	display:flex;
	awidth:100%;
	align-items: center;
	gap:30px;
	padding:0 10px;
	height:60px;
	border-radius:15px;
	font-size:var(--subtitle);
	text-transform:uppercase;
	margin:0 30px 20px 30px;
	border:2px solid transparent;
}
.register_option.selected 
{
	border-color:var(--dark-blue);
}
.register_option img 
{
	width:60px;
}
.register_know .register_option
{
	justify-content:center;
}
.register_bottom 
{
	apadding:20px 0;
	display:flex;
	flex-direction: column;
	gap:30px;
}

.register_confirm_item 
{
	margin-bottom:30px;
}
.register_confirm_label 
{
	color:var(--dark-grey);
}
.register_confirm_value 
{
	font-weight: bold;
}

/* ---- AUTENTICATION -----------------*/
.extend 
{
	flex:1;
}
.topbar 
{
	margin-bottom:40px;
	text-align: center;
	width:100%;
	display: flex;
	align-items: center;
	padding-top:20px;
}
.topbar .title 
{
	text-align:center;
	width:calc(100% - 56px);
	font-weight:400;
	
}
.auth 
{
	background:var(--light-blue);
}
.auth_img 
{
	width:25%;
	margin:0 auto 60px auto;
}

.auth input 
{
	background:var(--white);
	margin:20px 0 20px 0;
}
.auth .btn_default
{
	width:100%;
}
.auth_bottom
{
	margin-top:70px;
}
.auth label 
{
	font-size:var(--subtitle);
	width:100%;
	text-align: center;
	display:block;
	margin-bottom:20px;
}
.auth_social_section 
{
	display:flex;
	flex-direction: row;
	justify-content: center;
	padding:40px 10% 40px 10%;
	gap:30px;
}
.auth_social_btn  
{
	width:40px;
	height:40px;
}
.user_code 
{
	display:flex;
	justify-content: space-between;
	agap:10px;
	width:100%;
	margin-bottom:30px;
}
.user_code input 
{
	width:15%;
	height:60px;
	
	text-align:center;
	font-size:20px;
	border:none;
	border-radius:12px;
}
/* ---- QUESTIONS -----------------*/
.question_type_1_1 
{

}
.question_type_1 .main_image
{
	width: 70%;
    margin-bottom: 20px;
    aspect-ratio: 1 / 1;
    abackground: var(--light-grey);
	border:none;
}
.question_type_1_1 .answers
{
	display:flex;
	flex-direction:column;
	gap:10px;
	width:100%;
	flex-grow: 1;
    justify-content: end;
}
.question_type_1_2 .answers,
.question_type_1_4 .answers 
{
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	gap:20px;
	width:100%;
	flex-grow: 1;
    justify-content: center;
}
.question_type_1_2 .answers a, 
.question_type_1_4 .answers a 
{
	width:calc(50% - 10px);
}

.question_type_1_3 .answers 
{
	display:flex;
	flex-direction:column;
	gap:20px;
	width:100%;
	flex-grow: 1;
    justify-content: center;
}
.question_type_1_3 img
{
	flex:1;
	aspect-ratio:1/1;
	object-fit: contain;
	border:none;
}
.question_type_1_4 img
{
	flex:1;
	aspect-ratio:1/1;
	object-fit: contain;
	width:70%;
	margin:0 auto 10px auto;
	border:none;
}

.question_type_2 img
{
	flex:1;
	aspect-ratio:1/1;
	object-fit: contain;
	width:50%;
	margin:0 auto 10px auto;
	border:none;
}

.btn_answer
{
	padding:20px;
	width:100%;
	border:1px solid var(--medium-grey);
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	text-align:center;
	border-radius:20px;
	color:var(--black);
	text-decoration:none;
	transition:all 0.25s;
	user-select: none;
}
.question_type_1_3 .btn_answer 
{
	display:flex;
	flex-direction:row;
	gap:20px;
	align-items:center;
}
.question_type_1_3 .btn_answer img
{
	flex:1;
	display:block;
	width:33%;
}
.question_type_1_3 .btn_answer div
{
	flex:3;
}

.btn_answer.selected 
{
	abackground:var(--light-blue);
	border-color:var(--dark-blue);
	position:relative;
	overflow:hidden;
}
.btn_answer.selected::after
{
	position:absolute;
	content:'';
	left:0;
	top:0;
	width:100%;
	height:100%;
	opacity: .25;
    background: #00ccff;
	border-radius:20px;
}
.btn_answer.correct
{
	border-color:var(--dark-green);
	position:relative;
	overflow:hidden;
}
.btn_answer.correct::after
{
	position:absolute;
	content:'';
	left:0;
	top:0;
	width:100%;
	height:100%;
	opacity: .5;
    background: #56cf41;
	border-radius:20px;
}
.btn_answer.incorrect::after
{
	position:absolute;
	content:'';
	left:0;
	top:0;
	width:100%;
	height:100%;
	opacity: .45;
    background: #fc7369;
	border-radius:20px;
}

.question_type_2 .two_cols 
{
	display:flex;
	gap:20px;
	height:100%;
	width:100%;
}
.question_type_2 .col 
{
	flex:1;
	display:flex;
	flex-direction:column;
	justify-content:center;
	gap:20px;

	/* rev 1 */
	min-width:0;
}
.question_type_2 .col_2 .btn_answer 
{
	padding: 10px 15px;
}
.question_type_2 .col_2 img 
{
	margin:0 0 10px 0;
}

.question_5_text 
{
	border:1px solid var(--black);
	background:var(--light-grey);
	border-radius: 15px;
	padding:20px;
    flex-grow: 1;
    line-height: 1.5;
	font-size:var(--title);
}
.mistery_word 
{
	background:var(--light-blue);
	margin:0 5px 0 5px;
	padding:5px 10px 5px 5px;
	line-height:1;
	min-width:80px;
	amin-height:30px;
	position:relative;
	display:inline-block;
}
.mistery_word::before {
	content: '\00a0'; /* Espacio en blanco no rompible */
	display: inline-block;
	height: 100%; /* Asegura que el contenedor tenga la misma altura que el texto circundante */
	vertical-align: middle; /* Centra verticalmente el espacio en blanco */
}

.question_5_input 
{
	width:100%;
	padding-top:30px;
}
.question_5_input input 
{
	width:100%;
	padding:20px 20px;
	font-size:var(--title);
}
/* question type 3 */
.order_row 
{
	flex-grow:1;
	display:flex;
	flex-direction:row;
	width:100%;
	border:2px solid var(--orange);
	border-radius:10px;
	margin-bottom:20px;
	transition:all 0.25s;
}
.order_col 
{
	flex:1;
	text-align:center;
	display:flex;
	flex-direction:column;
	transition:all 0.25s;
}
.order_col_1 
{
	border-right:2px solid var(--orange);
}
.order_title 
{
	background:var(--yellow);
	padding:10px;
	border-bottom:2px solid var(--orange);
	/* rev 1 */
	min-height:60px;
	display:flex;
	align-items: center;
	justify-content:center;
}
.order_result 
{
	padding:10px 5px;
	flex:1;
	display:flex;
	flex-direction:column;
	gap:10px;
	align-items:center;

}
.answers_row 
{
	display:grid;
	grid-template-columns: repeat(3,1fr);
	width:100%;
	gap:10px;
}
.question_type_3 .question_content 
{
/* rev 1 */
align-items: normal;
}
.question_type_3 .question_type 
{
	/* rev 1 */
	min-height:100%;
	height:auto;
}
.question_type_3_1 .answers_row 
{
	grid-template-columns: repeat(2,1fr);
	gap:10px;
	padding:0 20px;
}
/*
question_type_4 .question_type
{
	height:auto;
}*/

.answer 
{
	background:white;
	padding:10px;
	border:1px solid var(--dark-grey);
	border-radius:10px;
	animation:calc(33.3% - 10px);
	text-align:center;
	transition:background-color 0.25s;
	display:flex;
	justify-content:center;
	align-items:center;
	flex-direction: column;
	gap:10px;
	position:relative;
}

.answer.correct::after
{
	position:absolute;
	content:'';
	left:0;
	top:0;
	width:100%;
	height:100%;
	/*background:rgb(0,255,0);
	opacity:0.20;*/
	opacity: .5;
    background: #56cf41;
	border-radius:10px;
}
.answer.incorrect::after
{
	position:absolute;
	content:'';
	left:0;
	top:0;
	width:100%;
	height:100%;
	opacity: .45;
    background: #fc7369;
	border-radius:10px;
}

.answer.selected::after
{
	position:absolute;
	content:'';
	left:0;
	top:0;
	width:100%;
	height:100%;
	opacity: .25;
    background: #00ccff;
	border-radius:10px;
	/*
	background:rgb(0,100,255);
	opacity:0.15;
	*/
}

.answer.dragging 
{
	position:fixed;
	z-index:1000;
	background:var(--white);
}

.answer.dragging::after
{
	position:absolute;
	content:'';
	left:0;
	top:0;
	width:100%;
	height:100%;
	opacity: .25;
    background: #00ccff;
	border-radius:10px;
	/*
	background:rgb(0,100,255);
	opacity:0.15;
	*/
}

.order_col .answer 
{
	width:90%;
}


/* question type 4 */
.question_type_4 .answer 
{
	flex:1;
}
.question_type_4 .answer.correct, .question_type_4 .answer.incorrect 
{
	transition:all 1s ease; 
}
.question_type_4 .question_type 
{
	height:auto;
}
.question_type_4 .question_content 
{
	align-items: normal;
}
.sort_row 
{
	display:flex;
	gap:30px;
	height:100%;
	width:100%;
}
.sort_col_values
{
	padding:0 10px;
	display:flex;
	flex-direction: column;
	height:100%;
	border-right:1px solid var(--dark-grey);
	flex:1;
}
.sort_value_1 
{
	flex:1;
}
.sort_col_answers
{
	flex:3;
	display:flex;
	flex-direction: column;
	gap:20px;
	justify-content: center;
}
.placeholder
{
	border:1px dotted var(--dark-blue);
	border-radius:10px;
	flex-shrink:0;
	flex-grow: 0;
}
.answer div 
{
	user-select: none;
	pointer-events:none;
}
.answer img 
{
	pointer-events:none;
	user-select: none;
	width:25%;
}

.horizontal .sort_row
{
	width:100%;
	flex-direction:column;
	justify-content: end;
}
.horizontal .sort_col_values
{
	flex-direction: row;
	border-right:none;
	border-bottom:1px solid var(--dark-grey);
	flex:0;
	padding-bottom:10px;
}
.horizontal .sort_col_answers
{
	flex-direction: row;
	align-items: end;
	flex:0;
}
.sort_col_image 
{
	flex:1;
	display: flex;
	align-items: center;
	justify-content: center;
}
.sort_col_image img 
{
	width:70%;
}

/* ---- FINISH -----------------*/
.finish_img
{
	flex:1;	
	display: flex;
    align-items: center;
}
.finish_img img 
{
	display:none;
	margin:0 auto;
}
.finish_img img.active
{
	display:block;
	width:90%;
	aspect-ratio: 1/1;
	object-fit: cover;
}
.finish_reward 
{
	display:flex;
	align-items:center;
	justify-content: center;
	font-size:var(--bigesttext);
	color:var(--dark-blue);
	font-weight:600;
	padding-bottom:100px;
}
.finish_reward img 
{
	margin-left:10px;
	width:70px;
	height:70px;
}
/* ---- organization -----------------*/
.main_top 
{
	display:flex;
	gap:20px;
	position:fixed;
	top:0;
	left:0;
	width:100%;
	padding:var(--padding-top) 20px 20px 20px;
	background:var(--white);
	z-index:10;
	align-items: center;
	justify-content: center;
}
.main_top h1 
{
	text-transform: uppercase;
	font-weight:normal;
	flex:1;
	text-align:center;
}

.main_top_btn 
{
	width:80px;
	height:20px;
	display: flex;
	gap:5px;
	text-decoration: none;
	color:var(--black);
	align-items: center;
	justify-content:end;
}
.main_top_btn img, .main_top_btn svg 
{
	width:20px;
}
.main_top_btn:first-child 
{
	justify-content: start;
}
.main_content 
{
	/* rev 2 */
	/*padding:70px 0 15vh 0;*/
	padding: 70px 0;
	flex:1;
	display:flex;
	gap:20px;
	align-items: center;
	flex-direction: column;
}
.main_footer 
{
	display:flex;
	gap:15px;
	flex-direction: column;
}

.top_header
{
	display:flex;
	gap:10px;
	position:fixed;
	top:0;
	left:0;
	width:100%;
	padding:var(--padding-top) 10px 20px 10px;
	background:var(--white);
	z-index:10;
}
.top_header .flex 
{
	flex:1;
}
.top_header h1 
{
	
}

.padding_tab
{
	padding-bottom:calc(120px + var(--padding-bottom)) !important;
}
.margin_tab 
{
	margin-bottom:60px;
}
.btn_back 
{
	width:28px;
}
.btn_back svg
{
	width:18px;
	margin-right:10px;
	height:18px;	
}
.top_header img 
{
	width:25px;
}
.top_header_stats
{
	display:flex;
	align-items: center;;
	gap:5px;
	text-decoration: none;
	color:var(--black);
}
.top_header_title 
{
	text-align:center;
}
.top_header_title.left 
{
	text-align:left;
}
/* ---- home -----------------*/
.main_grid 
{
	padding:70px 0 50px 0;
	aoverflow-y:scroll;
}

.subjects_footer 
{
	aposition:absolute;
	position:fixed;
	background: var(--white);
	bottom:0;
	left:0;
	width:100%;
	border-top:1px solid var(--dark-grey);
	padding:20px 30px var(--padding-bottom) 30px;
	display:flex;
	align-items: center;
	justify-content: space-between;
}

.ios_web .safe_area 
{
    padding-bottom: 50px !important;
}
/*
.ios .padding 
{
	padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom); 
}
*/

.subject_option img 
{
	width:30px;
}
.subject_option img.selected
{
	display:none;
}
.subject_option.selected img.selected
{
	display:block;
}
.subject_option.selected img.default
{
	display:none;
}

.main_grid_item 
{
	scroll-margin-top: 85px;
}

.grid_header 
{
	display:flex;
	margin-bottom:20px;
	align-items:center;
	gap:10px;
}
.grid_header .btn_open_item
{
	width:14px;
	height:14px;
	transition:all 0.5s;
}
.main_grid_item.open .btn_open_item
{
	transform:rotate(90deg);
}
.grid_title 
{
	flex:1;
	font-size:var(--title);
}
.grid_header_img
{
	width:50px;
	height:50px;
	object-fit: cover;
	border:2px solid var(--dark-blue);
	border-radius:50%;
}

.grid_items {
    max-height: 0;
    flex-wrap: wrap;
    gap: 5px;
    transition: all .5s;
    display: flex;
    overflow: hidden;
	margin-right:-5px;
}

.subject_units.grid_items 
{
	display:flex;
	flex-wrap: wrap;
	amargin-left:0;
	amargin-right:-15px;
	max-height:0px;
	overflow: hidden;
	transition:all 0.5s;
}

.grid_items.scroll
{
	overflow-x: auto;
	
	flex-wrap: nowrap;
	margin-right:0;
}

.main_grid_item.open .grid_items
{
	max-height:2000px;
	margin-bottom:30px;
}
.main_grid_item.open .grid_items.scroll
{
	padding-bottom:20px;
}
/*
.item
{
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content: center;
	border:2px solid var(--dark-blue);
	border-radius:20px;
	text-decoration: none;
	color:var(--black);
	padding:10px;
}*/
.item {
    border: 1px solid var(--dark-blue);
    /* border-top: none; */
    /* border-left: none; */
    color: var(--black);
    border-radius: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 5px 5px 5px;
    /* background: var(--light-blue); */
    text-decoration: none;
    display: flex;
}
.subject_items .item
{
	padding: 10px 5px;
	border-radius: 20px;
} 
.grid_items .item 
{
	width:calc(33.33% - 5px);
}

.grid_items.scroll .item 
{
	min-width:40%;
}

.item_img 
{
	padding:5px 15px 10px 15px;
	flex:3;
	object-fit:contain;
	aspect-ratio: 1 / 1;
}
.subject_unit_item .item_img 
{
	padding:10px 10px 20px 10px;
}
.item_img.border 
{
	border-bottom:1px solid var(--dark-blue);
}

.item_title 
{
    padding: 0 0 15px 0;
	text-align:center;
	flex:2;
}

.subject_unit_item .item_title 
{
    padding: 20px 0px;
}
.item_progress
{
	position:relative;
	height:20px;
	width:100%;
	background:var(--light-blue);
	border-radius:10px;
	overflow: hidden;
}
.item_progress_bar 
{
	position:absolute;
	top:0;
	left:0;
	width:0%;
	height:100%;
	background:var(--dark-blue);
	border-radius:0 10px 10px 0;
}
.item_progress_text 
{
	position:absolute;
	color:var(--white);
	top:2px;
	left:5px;
}

.section_item
{

}
.section_item .grid_header 
{
	background: var(--light-blue);
	border-radius:20px;
	border:2px solid var(--dark-blue);
	align-items:center;
	gap:0;
}
.section_item .grid_title
{
	padding:20px;
	border-right:2px solid var(--dark-blue);
	flex:2;
	margin-left:0;
}
.section_item .grid_time 
{
	aflex:1;
	display:flex;
	gap:3px;
	justify-content: center;
	padding:20px;
}
.grid_time .time_clock
{
	width:14px;
	height:14px;
	margin-right:5px;
}
.section_test_item 
{
	position:relative;
	overflow: hidden;;
}
.section_test_item.done::after
{
	content:"";
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	opacity: .25;
    background: #00ccff;
}

.start_info 
{
	padding:50px 0 20px 0;
	flex:1;
	display:flex;
	gap:20px;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.end_test_restart 
{
	font-size:var(--bigtitle);
	margin-bottom:50px;
}
.start_test_name 
{
	text-align:center;
}
.start_test_img 
{
	width:80%;
}
.start_footer 
{
	display:flex;
	gap:15px;
	flex-direction: column;
	padding-bottom:20px;
}

/* ---- Library ----- */
#library_empty 
{
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content:center;
	flex:1;
}
#library_empty img 
{
	margin-top:30px;
	width:35%;
}
#library_items 
{
	width:100%;
}
.library_subject 
{
	padding:20px 10px 30px 20px;
	border:2px solid var(--dark-blue);	
	width:100%;
	border-radius:15px;
	margin-bottom:20px;
}
.library_grid 
{
	display:flex;
	flex-wrap:wrap;
	gap:10px;
	width:100%;
	margin-bottom:40px;
}
.library_item 
{
	width:calc(20% - 10px);
	border:1px solid var(--black);
	margin-bottom:10px;
}
.library_subject_title 
{
	margin-bottom:8px;
}
#library_image 
{
	position:fixed;
	z-index:100;
	top:0;
	left:0;
	width:100%;
	height:100%;
	display:none;
}
/*
#library_image::after
{
	content:"";
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.5);
}*/
#library_image.show 
{
	display:block;
}
#library_popup
{
	border-top:2px solid var(--medium-grey);
}
/* ---- Poopus bottom ----- */
.popup_main_img 
{
	width:20%;
}
.popup_bottom
{
	position:fixed;
	bottom:0;
	left:0;
	width:100%;
	z-index:600;
	background:var(--white);
	border-top:1px solid var(--dark-blue);
	border-radius:20px 20px 0 0;
	padding:30px 20px 40px 20px;
	display:flex;
	flex-direction: column;
	align-items: center;
	gap:40px;
	transform: translateY(100%); /* Mueve el popup fuera de la vista */
    opacity: 0;
	transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.popup_top 
{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	z-index:200;
	background:var(--white);
	border-bottom:1px solid var(--medium-grey);
	border-radius:0 0 20px 20px;
	padding:0;
	display:flex;
	flex-direction: column;
	align-items: center;
	gap:30px;
	transform: translateY(-100%); 
    opacity: 0;
	transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.popup_test 
{
	position:fixed;
	bottom:0;
	left:0;
	width:100%;
	z-index:200;
	background:var(--white);
	padding:0px 0 25px 0;
	border-radius:20px 20px 0 0;
	display:flex;
	flex-direction: column;
	align-items: center;
	gap:5px;
	transform: translateY(100%); /* Mueve el popup fuera de la vista */
    opacity: 0;
	transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.popup_top.show 
{
	transform: translateY(0); 
    opacity: 1;
}
.popup_test_links 
{
	width:100%;
}
.popup_test_link 
{
	padding:20px 10px;
	display:flex;
	font-size:var(--subtitle);
	gap:30px;
	border-bottom:1px solid var(--medium-grey);
	align-items: center;
	justify-content: center;
	text-decoration: none;
	color:var(--black);
}
.popup_test_link.cancel 
{
	font-weight:bold;
	color:var(--dark-red);
	margin-top:10px;
	border-bottom:none;
}
.popup_test_link img 
{
	width:20px;
}

.popup_test_link .check_saved
{
	display:none;
}
.popup_test_link.saved .saved
{
	display:block;
}
.popup_test_link.saved .no_saved
{
	display:none;
}
.popup_test_link .no_saved
{
	display:block;
}

.profile_links 
{
	width:100%;
}
.profile_link
{
	display:block;
	padding:30px 40px;
	border-bottom:1px solid var(--medium-grey);
	font-size:var(--subtitle);
	display:flex;
	gap:30px;
	text-decoration: none;
	color:var(--black);
}
.profile_link img 
{
	width:20px;
}
.profile_link:last-child
{
	border-bottom:none;
}
.popup_close 
{
	position:absolute;
	top:25px;
	right:25px;
}
.popup_close svg 
{
	width:24px;
	height:24px;
	fill:var(--dark-blue);
}
.popup_bottom h1 
{
	font-weight:400;
}
.popup_bottom_text 
{
	font-weight:400;
	margin:20px 0 10px 0;
}
#popup_streak .popup_bottom_text 
{
	margin-top:10px;
}
.popup_bottom.show, .popup_test.show 
{
	transform: translateY(0); /* Mueve el popup a su posición final */
    opacity: 1;
}
.popup_bottom_img 
{
	width:15%;
}
.ads_coins 
{
	padding-left:10px;
}
.popup_bottom .btn_default 
{
	width:100%;
	text-transform: none;
}
.popup_streak_data
{
	margin-top:-15px;
	font-weight:400;
}

.popup_bottom_body 
{
	display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.streak_week 
{
	display:flex;
	width:100%;
	gap:5px;
}

.week_day 
{
	font-size:var(--subtitle);
	color:var(--dark-blue);
	border:1px solid var(--dark-blue);
	border-radius:50%;
	flex:1;
	aspect-ratio: 1/1;
	display:flex;
	align-items: center;
	justify-content: center;
}
.week_day.done 
{
	background:var(--dark-blue);
	color:var(--white);
}

#alert_popup .popup_close 
{
	position:static
}
#alert_popup.error 
{
	border-color:var(--dark-red);
	background:var(--dark-red);
	border-width: 2px;;
	color:white;
} 
.alert_msg 
{
	font-size:var(--subtitle);
	text-align: center;
}
#alert_popup.error .btn_white 
{
	background:var(--light-red);
	border:none;
	color:var(--black);
}
.alert_background 
{
	position:fixed;
	z-index:200;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.2);
}
.alert_popup_answers
{
	display:flex;
	gap:10px;
	justify-content: space-around;
	width:100%;
}

/* ---- CAPTURE -----------------*/
#capture_picture
{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:300;
	display:none;
	transition:all 1s;
	transform-origin: left bottom;
	aborder:2px solid var(--dark-grey);
	-webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
}
#capture_picture.close
{
	atransform: scale(0);
	animation: capture_animation 1s ease-in-out forwards;
}
@keyframes capture_animation {
	0% {
		transform: scale(1) translate(0, 0);
		opacity: 1;
	}
	80% {
		transform: scale(0.2) translate(10vw, -10vh);
		opacity: 1;
	}
	100% {
		transform: scale(0.2) translate(10vw, -10vh);
		opacity: 0;
	}
}


.capture_image, #capture_picture::after
{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
}
#capture_picture::after
{
	display:block;
	background:var(--white);
	content:"";
	opacity:1;
	transition:opacity 0.5s;
}
#capture_picture.show
{
	display:flex;
	align-items: center;
	justify-content: center;
}
#capture_picture.reveal::after
{
	opacity:0;
}
/*
.capture_msg 
{
	z-index: 301;
    background: var(--light-blue);
    border: 2px solid var(--dark-blue);
    padding: 30px;
    border-radius: 15px;
    font-size: var(--subtitle);
	opacity: 0;
	transition:opacity 2s;
}
#capture_picture.reveal .capture_msg
{
	opacity:1;
}
*/

/* ---- PROGRESS -----------------*/
.progress_section 
{	
	margin-bottom:30px;
	text-align:left;
	width:100%;
}
.progress_title 
{
	font-size: var(--title);
	font-weight:bold;
	margin-bottom: 20px;
}
.progress_empty 
{
	font-weight: 400;
    font-size: var(--title);
    margin: 40px 0 20px 0;
	text-align:center;
}
.progress_content 
{
	display:flex;
	align-items: center;
	justify-content: center;
	gap:20px;
}
.progress_time img
{
	width:70%;
	margin-left:auto;
}
.progress_time_value 
{
	font-size:50px;
	font-weight:600;
	line-height:1;

}

.progress_position 
{
	width:66%;
}

.points_grid
{
	flex-wrap: wrap;
	gap:10px;
	align-items: end;
}
.points_item
{
	text-align:center;
	width:calc(33.33% - 10px);
	margin-bottom:20px;
}
.points_title 
{
	font-size:var(--title);
	margin-bottom:10px;
	line-height:1.2;
}

.points_result 
{
	display:flex;
	gap:5px;
	justify-content: center;
	align-items: center;
	margin-bottom: 5px;;
}
.points_result img 
{
	width:18px;
	height:18px;
}
.points_result_answers
{
	font-size:var(--title);
	font-weight:600;
}

.progress_position 
{
	position:relative;
}
.progress_needle {
	position: absolute;
	height: 100%;
	bottom: -5px;
	left: calc(50% - 5%);
	transform: rotate(0deg);
	transform-origin: 50% 95%;
	transition: transform 1s ease-in-out;
	display: flex;
    align-items: end;
}
.needle 
{
	width:100%;
	height:90%;
}

.gauge_container {
	position: relative;
	width: 100%;
	height: 100%;
	margin-bottom:10px;
}

.gauge {
	transform: rotate(-90deg); 
}

.gauge circle {
	fill: none;
	stroke-width: 10;
}

.gauge .bg_circle {
	stroke: var(--light-blue); 
}

.gauge .progress_circle {
	stroke: var(--dark-blue); 
	stroke-linecap: round; 
	stroke-dasharray: 0, 100; 
	transition: stroke-dasharray 1s; 
}

.gauge_label {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.ranking_list 
{
	display:flex;
	flex-direction: column;
	margin:30px 0 20px 0;
	border:2px solid var(--dark-blue);
	border-radius:20px;
	width:100%;
	height:100%;
	overflow: hidden;
	font-size:var(--subtitle);
}
.ranking_item
{
	display: flex;
    flex: 1;
    padding: 20px 30px;
    align-items: center;
	gap:40px;
}
.ranking_item:first-child
{
	background:var(--light-blue);
	color:var(--dark-blue);
	font-weight: 600;;
}
.ranking_name 
{
	flex:1;
}
.ranking_avatar 
{
	width:50px;
	height:50px;
}
/* ---- PROFILE -----------------*/
.profile_avatar 
{
	width:30%;
	margin:10px 0 20px 0;
	text-decoration: none;
	color:var(--black);
	text-align: center;
	font-size:var(--subtitle);
}
.profile_avatar img 
{
	margin-bottom:10px;
}
.profile_field 
{
	border:1px solid var(--dark-blue);
	padding:10px 20px;
	width:100%;
	border-radius:10px;
	display:flex;
	gap:20px;
}
.profile_field img 
{
	width:20px;
	aheight:20px;
	object-fit:contain;
}
.profile_field input 
{
	border:none;
	outline:none;
	width:100%;
	font-size:var(--title);
}
.avatar_grid 
{
	display:flex;
	flex-wrap:wrap;
	padding-top:10px;
}
.avatar_item 
{
	width:33.33%;
	padding:10px 5px 20px 5px;
}
.avatar_item_content
{
	padding:8px 4px;
}
.avatar_item.selected .avatar_item_content
{
	border:2px solid var(--dark-blue);
	border-radius:15px;
}
.avatar_buy 
{
	width:100%;
	text-align: center;
}
/* ---- RESPONSIVE -----------------*/

@media (max-width: 820px) 
{
	
	body {
		background-color: white;
	}
	.app-content 
	{
		transform: none !important;
	    width: 100%;
    	height: 100% !important;
	}
	
	/*
	.top_header, .subjects_footer 
	{
		position:fixed;
	}
	.main_grid 
	{
		overflow-y:visible;
	}*/
}