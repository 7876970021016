@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 200;
  src: url("poppins-v21-latin-200.39a0b67c.woff2") format("woff2");
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  src: url("poppins-v21-latin-regular.1110a71c.woff2") format("woff2");
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  src: url("poppins-v21-latin-600.414c1233.woff2") format("woff2");
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  src: url("poppins-v21-latin-800.48eaf35d.woff2") format("woff2");
}

:root {
  --white: #fff;
  --black: #4b4b4b;
  --dark-blue: #567196;
  --light-blue: #c0f2ff;
  --dark-grey: #6b717c;
  --medium-grey: #ccc;
  --light-grey: #e9e8e7;
  --dark-green: #339948;
  --light-green: #a6df91;
  --dark-red: #ed7157;
  --light-red: #fcc3b9;
  --orange: #f9b06e;
  --yellow: #fcec6f;
  --bigesttext: 80px;
  --bigtext: 50px;
  --bigtitle: 24px;
  --title: 18px;
  --subtitle: 15px;
  --text: 13px;
  --tinytext: 7px;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: #0000;
}

.cordova {
  display: none;
}

.app_cordova .cordova {
  display: block;
}

.app_cordova .no_cordova {
  display: none;
}

img {
  width: 100%;
  display: block;
}

html {
  font-family: Poppins, sans-serif;
  font-size: var(--text);
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body {
  max-width: 800px;
  height: 100vh;
  background-color: var(--white);
  margin: 0 auto;
  line-height: 1.2;
  display: flex;
}

input {
  font-family: Poppins, sans-serif;
}

input:focus {
  outline: 2px solid var(--dark-blue) !important;
}

h1, h2, h3, h4 {
  margin: 0;
}

h1 {
  font-weight: 600;
  font-size: var(--title);
}

h2 {
  font-weight: 600;
  font-size: var(--subtitle);
}

h3 {
  font-weight: 400;
  font-size: var(--subtitle);
}

h4 {
  font-weight: 400;
  font-size: var(--bigtitle);
}

.app-content {
  background: var(--white);
  width: 100%;
  height: 100%;
  apadding: 20px;
  box-sizing: border-box;
  transform-origin: 0 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.hide {
  display: none !important;
}

section {
  min-height: 100%;
  flex-direction: column;
  display: none;
}

section.active {
  display: flex;
}

.padding {
  --padding-top: 18px;
  --padding-bottom: 20px;
  padding: 10px;
}

.link_default {
  color: var(--dark-blue);
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  display: block;
}

.btn_default {
  font-size: var(--subtitle);
  background: var(--dark-blue);
  border: 2px solid var(--dark-blue);
  color: var(--white);
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  border-radius: 15px;
  padding: 20px 10px;
  text-decoration: none;
  display: block;
}

.btn_white {
  background: var(--white);
  color: var(--dark-blue);
  justify-content: center;
  align-items: center;
  display: flex;
}

.btn_white img {
  width: 20px;
  margin: 0 5px 0 10px;
}

.btn_white img.left {
  margin: 0 15px 0 5px;
}

.btn_warning {
  background: var(--dark-red);
  border-color: var(--dark-red);
}

.btn_small {
  border: 2px solid var(--dark-blue);
  color: var(--dark-blue);
  border-radius: 25px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2px;
  margin-top: 15px;
  padding: 7px 10px;
  text-decoration: none;
  display: flex;
}

.btn_small img {
  width: 15px;
  margin-left: 5px;
}

.btn_small .value {
  font-weight: 600;
}

.link_default {
  color: var(--dark-blue);
  text-align: center;
  width: 100%;
  padding: 5px 10px;
  text-decoration: none;
  display: block;
}

.link_warning {
  color: var(--dark-red);
  border-color: var(--dark-red);
}

.splash {
  background: var(--light-blue);
  justify-content: center;
  align-items: center;
}

.splash img {
  width: 80%;
  margin-bottom: 10%;
}

.restart_reward {
  padding-left: 10px;
}

.custom_progress {
  width: 100%;
  height: 12px;
  background: var(--light-blue);
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}

.custom_progress_value {
  content: "";
  width: 50%;
  height: 100%;
  background: var(--dark-blue);
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
}

.test_header_top {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  align-items: start;
  gap: 10px;
  margin-bottom: 10px;
  display: flex;
}

.test_progress {
  width: 100%;
  height: 20px;
}

.test_close {
  width: 50px;
}

.test_close img {
  width: 15px;
  height: 15px;
}

.test_info {
  text-align: center;
  flex: 1;
}

.test_coins {
  width: 50px;
  justify-content: end;
  align-items: center;
  gap: 5px;
  display: flex;
}

.test_coins img {
  width: 20px;
  height: 20px;
}

.test_name {
  font-size: var(--subtitle);
  color: var(--dark-blue);
  font-weight: 400;
}

.test_header {
  padding: 20px;
}

.test_content {
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 20px 20px;
  display: flex;
}

.question_type {
  flex-direction: column;
  flex: 1;
  align-items: center;
  display: flex;
}

.question_info {
  text-transform: uppercase;
  text-align: center;
  color: var(--dark-blue);
  font-size: var(--subtitle);
  margin-bottom: 10px;
}

.question_text {
  font-size: var(--title);
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
  line-height: 1.3;
}

.question_content {
  width: 100%;
  flex: 1;
  align-items: center;
  display: flex;
}

.test_footer {
  border-top: 2px solid #0000;
  border-radius: 20px 20px 0 0;
  padding: 5px 20px 40px;
}

.test_footer_header {
  visibility: hidden;
  height: 30px;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.test_footer_header_info {
  flex: 1;
}

.btn_check_test {
  text-align: center;
  background: var(--light-grey);
  font-size: var(--subtitle);
  color: inherit;
  text-transform: uppercase;
  border-radius: 10px;
  padding: 25px 20px;
  line-height: 1;
  text-decoration: none;
  transition: all .25s;
  display: block;
}

.test_footer.ready .btn_check_test {
  background: var(--dark-blue);
  color: var(--white);
}

#test.correct .test_footer {
  background: var(--light-green);
  border-color: var(--dark-green);
}

#test.incorrect .test_footer {
  background: var(--light-red);
  border-color: var(--dark-red);
}

#test.correct .btn_check_test {
  background: var(--dark-green);
}

#test.incorrect .btn_check_test {
  background: var(--dark-red);
}

#test.correct .test_footer_header {
  visibility: visible;
  color: var(--dark-green);
  fill: var(--dark-green);
}

#test.incorrect .test_footer_header {
  visibility: visible;
  color: var(--dark-red);
  fill: var(--dark-red);
}

.test_result {
  align-items: center;
  gap: 5px;
  display: none;
}

.test_result img {
  width: 24px;
}

#test.correct .text_ok, #test.incorrect .text_ko {
  display: flex;
}

.btn_text_continue, #test.correct .btn_text_check, #test.incorrect .btn_text_check {
  display: none;
}

#test.correct .btn_text_continue, #test.incorrect .btn_text_continue {
  display: block;
}

.restart_section .test_content {
  ajustify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding-top: 40px;
  display: flex;
}

.restart_section {
  text-align: center;
}

.register_top {
  align-items: center;
  gap: 10px;
  display: flex;
}

.register_top progress {
  flex: 1;
}

.register_top progress[value] {
  --color: var(--dark-blue);
  --background: var(--light-blue);
  appearance: none;
  height: 12px;
  background: none;
  border: none;
  border-radius: 10em;
}

.register_top progress[value]::-webkit-progress-bar {
  background: var(--background);
  border-radius: 10em;
}

.register_top progress[value]::-webkit-progress-value {
  background: var(--color);
  border-radius: 10em;
}

.register_top progress[value]::-moz-progress-bar {
  background: var(--color);
  border-radius: 10em;
}

.register_body {
  text-align: center;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding-top: 50px;
  display: flex;
}

.register_body label {
  font-size: var(--subtitle);
  margin-bottom: 30px;
  line-height: 1.4;
  display: block;
}

.register_body .register_start_info {
  font-weight: 400;
  font-size: var(--bigtitle);
  margin-bottom: 70px;
}

.register_body .register_start_title {
  font-weight: 400;
  font-size: var(--title);
  margin-bottom: 50px;
}

.register_img {
  width: 40%;
  margin-bottom: 50px;
}

.register_values {
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
}

.input_container {
  position: relative;
}

.input_container.last {
  margin-bottom: 20px;
}

.register_select {
  font-size: var(--subtitle);
  background: var(--light-blue);
  atext-align: center;
  aheight: 50px;
  border: none;
  border-radius: 10px;
  padding: 20px 10px;
}

.input_placeholder {
  font-size: var(--subtitle);
  color: var(--dark-grey);
  display: flex;
  position: absolute;
  top: calc(50% - 8px);
  left: 18px;
}

.input_placeholder svg {
  height: 15px;
  fill: var(--dark-grey);
  margin-right: 18px;
}

.input_container input:not(:placeholder-shown) + .input_placeholder {
  opacity: 0;
}

input.register_select {
  width: 100%;
}

input.register_select.left {
  text-align: left;
  padding: 20px 18px;
}

.col {
  flex: 1;
}

.register_options {
  width: 100%;
  margin-bottom: 40px;
}

.register_option {
  background: var(--light-blue);
  awidth: 100%;
  height: 60px;
  font-size: var(--subtitle);
  text-transform: uppercase;
  border: 2px solid #0000;
  border-radius: 15px;
  align-items: center;
  gap: 30px;
  margin: 0 30px 20px;
  padding: 0 10px;
  display: flex;
}

.register_option.selected {
  border-color: var(--dark-blue);
}

.register_option img {
  width: 60px;
}

.register_know .register_option {
  justify-content: center;
}

.register_bottom {
  apadding: 20px 0;
  flex-direction: column;
  gap: 30px;
  display: flex;
}

.register_confirm_item {
  margin-bottom: 30px;
}

.register_confirm_label {
  color: var(--dark-grey);
}

.register_confirm_value {
  font-weight: bold;
}

.extend {
  flex: 1;
}

.topbar {
  text-align: center;
  width: 100%;
  align-items: center;
  margin-bottom: 40px;
  padding-top: 20px;
  display: flex;
}

.topbar .title {
  text-align: center;
  width: calc(100% - 56px);
  font-weight: 400;
}

.auth {
  background: var(--light-blue);
}

.auth_img {
  width: 25%;
  margin: 0 auto 60px;
}

.auth input {
  background: var(--white);
  margin: 20px 0;
}

.auth .btn_default {
  width: 100%;
}

.auth_bottom {
  margin-top: 70px;
}

.auth label {
  font-size: var(--subtitle);
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  display: block;
}

.auth_social_section {
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  padding: 40px 10%;
  display: flex;
}

.auth_social_btn {
  width: 40px;
  height: 40px;
}

.user_code {
  agap: 10px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
  display: flex;
}

.user_code input {
  width: 15%;
  height: 60px;
  text-align: center;
  border: none;
  border-radius: 12px;
  font-size: 20px;
}

.question_type_1 .main_image {
  width: 70%;
  aspect-ratio: 1 / 1;
  abackground: var(--light-grey);
  border: none;
  margin-bottom: 20px;
}

.question_type_1_1 .answers {
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  justify-content: end;
  gap: 10px;
  display: flex;
}

.question_type_1_2 .answers, .question_type_1_4 .answers {
  width: 100%;
  flex-flow: wrap;
  flex-grow: 1;
  justify-content: center;
  gap: 20px;
  display: flex;
}

.question_type_1_2 .answers a, .question_type_1_4 .answers a {
  width: calc(50% - 10px);
}

.question_type_1_3 .answers {
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  gap: 20px;
  display: flex;
}

.question_type_1_3 img {
  aspect-ratio: 1 / 1;
  object-fit: contain;
  border: none;
  flex: 1;
}

.question_type_1_4 img {
  aspect-ratio: 1 / 1;
  object-fit: contain;
  width: 70%;
  border: none;
  flex: 1;
  margin: 0 auto 10px;
}

.question_type_2 img {
  aspect-ratio: 1 / 1;
  object-fit: contain;
  width: 50%;
  border: none;
  flex: 1;
  margin: 0 auto 10px;
}

.btn_answer {
  width: 100%;
  border: 1px solid var(--medium-grey);
  text-align: center;
  color: var(--black);
  -webkit-user-select: none;
  user-select: none;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-decoration: none;
  transition: all .25s;
  display: flex;
}

.question_type_1_3 .btn_answer {
  flex-direction: row;
  align-items: center;
  gap: 20px;
  display: flex;
}

.question_type_1_3 .btn_answer img {
  width: 33%;
  flex: 1;
  display: block;
}

.question_type_1_3 .btn_answer div {
  flex: 3;
}

.btn_answer.selected {
  abackground: var(--light-blue);
  border-color: var(--dark-blue);
  position: relative;
  overflow: hidden;
}

.btn_answer.selected:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: .25;
  background: #0cf;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.btn_answer.correct {
  border-color: var(--dark-green);
  position: relative;
  overflow: hidden;
}

.btn_answer.correct:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: .5;
  background: #56cf41;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.btn_answer.incorrect:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: .45;
  background: #fc7369;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.question_type_2 .two_cols {
  height: 100%;
  width: 100%;
  gap: 20px;
  display: flex;
}

.question_type_2 .col {
  min-width: 0;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  gap: 20px;
  display: flex;
}

.question_type_2 .col_2 .btn_answer {
  padding: 10px 15px;
}

.question_type_2 .col_2 img {
  margin: 0 0 10px;
}

.question_5_text {
  border: 1px solid var(--black);
  background: var(--light-grey);
  line-height: 1.5;
  font-size: var(--title);
  border-radius: 15px;
  flex-grow: 1;
  padding: 20px;
}

.mistery_word {
  background: var(--light-blue);
  min-width: 80px;
  amin-height: 30px;
  margin: 0 5px;
  padding: 5px 10px 5px 5px;
  line-height: 1;
  display: inline-block;
  position: relative;
}

.mistery_word:before {
  content: " ";
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.question_5_input {
  width: 100%;
  padding-top: 30px;
}

.question_5_input input {
  width: 100%;
  font-size: var(--title);
  padding: 20px;
}

.order_row {
  width: 100%;
  border: 2px solid var(--orange);
  border-radius: 10px;
  flex-direction: row;
  flex-grow: 1;
  margin-bottom: 20px;
  transition: all .25s;
  display: flex;
}

.order_col {
  text-align: center;
  flex-direction: column;
  flex: 1;
  transition: all .25s;
  display: flex;
}

.order_col_1 {
  border-right: 2px solid var(--orange);
}

.order_title {
  background: var(--yellow);
  border-bottom: 2px solid var(--orange);
  min-height: 60px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
}

.order_result {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 10px;
  padding: 10px 5px;
  display: flex;
}

.answers_row {
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  display: grid;
}

.question_type_3 .question_content {
  align-items: normal;
}

.question_type_3 .question_type {
  min-height: 100%;
  height: auto;
}

.question_type_3_1 .answers_row {
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 0 20px;
}

.answer {
  border: 1px solid var(--dark-grey);
  animation: calc(33.3% - 10px);
  text-align: center;
  background: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  transition: background-color .25s;
  display: flex;
  position: relative;
}

.answer.correct:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: .5;
  background: #56cf41;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.answer.incorrect:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: .45;
  background: #fc7369;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.answer.selected:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: .25;
  background: #0cf;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.answer.dragging {
  z-index: 1000;
  background: var(--white);
  position: fixed;
}

.answer.dragging:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: .25;
  background: #0cf;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.order_col .answer {
  width: 90%;
}

.question_type_4 .answer {
  flex: 1;
}

.question_type_4 .answer.correct, .question_type_4 .answer.incorrect {
  transition: all 1s;
}

.question_type_4 .question_type {
  height: auto;
}

.question_type_4 .question_content {
  align-items: normal;
}

.sort_row {
  height: 100%;
  width: 100%;
  gap: 30px;
  display: flex;
}

.sort_col_values {
  height: 100%;
  border-right: 1px solid var(--dark-grey);
  flex-direction: column;
  flex: 1;
  padding: 0 10px;
  display: flex;
}

.sort_value_1 {
  flex: 1;
}

.sort_col_answers {
  flex-direction: column;
  flex: 3;
  justify-content: center;
  gap: 20px;
  display: flex;
}

.placeholder {
  border: 1px dotted var(--dark-blue);
  border-radius: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}

.answer div {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.answer img {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  width: 25%;
}

.horizontal .sort_row {
  width: 100%;
  flex-direction: column;
  justify-content: end;
}

.horizontal .sort_col_values {
  border-right: none;
  border-bottom: 1px solid var(--dark-grey);
  flex-direction: row;
  flex: 0;
  padding-bottom: 10px;
}

.horizontal .sort_col_answers {
  flex-direction: row;
  flex: 0;
  align-items: end;
}

.sort_col_image {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sort_col_image img {
  width: 70%;
}

.finish_img {
  flex: 1;
  align-items: center;
  display: flex;
}

.finish_img img {
  margin: 0 auto;
  display: none;
}

.finish_img img.active {
  width: 90%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  display: block;
}

.finish_reward {
  font-size: var(--bigesttext);
  color: var(--dark-blue);
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  font-weight: 600;
  display: flex;
}

.finish_reward img {
  width: 70px;
  height: 70px;
  margin-left: 10px;
}

.main_top {
  width: 100%;
  padding: var(--padding-top) 20px 20px 20px;
  background: var(--white);
  z-index: 10;
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.main_top h1 {
  text-transform: uppercase;
  text-align: center;
  flex: 1;
  font-weight: normal;
}

.main_top_btn {
  width: 80px;
  height: 20px;
  color: var(--black);
  justify-content: end;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  display: flex;
}

.main_top_btn img, .main_top_btn svg {
  width: 20px;
}

.main_top_btn:first-child {
  justify-content: start;
}

.main_content {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 20px;
  padding: 70px 0;
  display: flex;
}

.main_footer {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.top_header {
  width: 100%;
  padding: var(--padding-top) 10px 20px 10px;
  background: var(--white);
  z-index: 10;
  gap: 10px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.top_header .flex {
  flex: 1;
}

.padding_tab {
  padding-bottom: calc(120px + var(--padding-bottom)) !important;
}

.margin_tab {
  margin-bottom: 60px;
}

.btn_back {
  width: 28px;
}

.btn_back svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.top_header img {
  width: 25px;
}

.top_header_stats {
  color: var(--black);
  align-items: center;
  gap: 5px;
  text-decoration: none;
  display: flex;
}

.top_header_title {
  text-align: center;
}

.top_header_title.left {
  text-align: left;
}

.main_grid {
  aoverflow-y: scroll;
  padding: 70px 0 50px;
}

.subjects_footer {
  aposition: absolute;
  background: var(--white);
  width: 100%;
  border-top: 1px solid var(--dark-grey);
  padding: 20px 30px var(--padding-bottom) 30px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
}

.ios_web .safe_area {
  padding-bottom: 50px !important;
}

.subject_option img {
  width: 30px;
}

.subject_option img.selected {
  display: none;
}

.subject_option.selected img.selected {
  display: block;
}

.subject_option.selected img.default {
  display: none;
}

.main_grid_item {
  scroll-margin-top: 85px;
}

.grid_header {
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  display: flex;
}

.grid_header .btn_open_item {
  width: 14px;
  height: 14px;
  transition: all .5s;
}

.main_grid_item.open .btn_open_item {
  transform: rotate(90deg);
}

.grid_title {
  font-size: var(--title);
  flex: 1;
}

.grid_header_img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border: 2px solid var(--dark-blue);
  border-radius: 50%;
}

.grid_items {
  max-height: 0;
  flex-wrap: wrap;
  gap: 5px;
  margin-right: -5px;
  transition: all .5s;
  display: flex;
  overflow: hidden;
}

.subject_units.grid_items {
  amargin-left: 0;
  amargin-right: -15px;
  max-height: 0;
  flex-wrap: wrap;
  transition: all .5s;
  display: flex;
  overflow: hidden;
}

.grid_items.scroll {
  flex-wrap: nowrap;
  margin-right: 0;
  overflow-x: auto;
}

.main_grid_item.open .grid_items {
  max-height: 2000px;
  margin-bottom: 30px;
}

.main_grid_item.open .grid_items.scroll {
  padding-bottom: 20px;
}

.item {
  border: 1px solid var(--dark-blue);
  color: var(--black);
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 5px 5px;
  text-decoration: none;
  display: flex;
}

.subject_items .item {
  border-radius: 20px;
  padding: 10px 5px;
}

.grid_items .item {
  width: calc(33.33% - 5px);
}

.grid_items.scroll .item {
  min-width: 40%;
}

.item_img {
  object-fit: contain;
  aspect-ratio: 1 / 1;
  flex: 3;
  padding: 5px 15px 10px;
}

.subject_unit_item .item_img {
  padding: 10px 10px 20px;
}

.item_img.border {
  border-bottom: 1px solid var(--dark-blue);
}

.item_title {
  text-align: center;
  flex: 2;
  padding: 0 0 15px;
}

.subject_unit_item .item_title {
  padding: 20px 0;
}

.item_progress {
  height: 20px;
  width: 100%;
  background: var(--light-blue);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.item_progress_bar {
  width: 0%;
  height: 100%;
  background: var(--dark-blue);
  border-radius: 0 10px 10px 0;
  position: absolute;
  top: 0;
  left: 0;
}

.item_progress_text {
  color: var(--white);
  position: absolute;
  top: 2px;
  left: 5px;
}

.section_item .grid_header {
  background: var(--light-blue);
  border: 2px solid var(--dark-blue);
  border-radius: 20px;
  align-items: center;
  gap: 0;
}

.section_item .grid_title {
  border-right: 2px solid var(--dark-blue);
  flex: 2;
  margin-left: 0;
  padding: 20px;
}

.section_item .grid_time {
  aflex: 1;
  justify-content: center;
  gap: 3px;
  padding: 20px;
  display: flex;
}

.grid_time .time_clock {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.section_test_item {
  position: relative;
  overflow: hidden;
}

.section_test_item.done:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: .25;
  background: #0cf;
  position: absolute;
  top: 0;
  left: 0;
}

.start_info {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 50px 0 20px;
  display: flex;
}

.end_test_restart {
  font-size: var(--bigtitle);
  margin-bottom: 50px;
}

.start_test_name {
  text-align: center;
}

.start_test_img {
  width: 80%;
}

.start_footer {
  flex-direction: column;
  gap: 15px;
  padding-bottom: 20px;
  display: flex;
}

#library_empty {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

#library_empty img {
  width: 35%;
  margin-top: 30px;
}

#library_items {
  width: 100%;
}

.library_subject {
  border: 2px solid var(--dark-blue);
  width: 100%;
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 20px 10px 30px 20px;
}

.library_grid {
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 40px;
  display: flex;
}

.library_item {
  width: calc(20% - 10px);
  border: 1px solid var(--black);
  margin-bottom: 10px;
}

.library_subject_title {
  margin-bottom: 8px;
}

#library_image {
  z-index: 100;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#library_image.show {
  display: block;
}

#library_popup {
  border-top: 2px solid var(--medium-grey);
}

.popup_main_img {
  width: 20%;
}

.popup_bottom {
  width: 100%;
  z-index: 600;
  background: var(--white);
  border-top: 1px solid var(--dark-blue);
  opacity: 0;
  border-radius: 20px 20px 0 0;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 30px 20px 40px;
  transition: transform .5s ease-in-out, opacity .5s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
}

.popup_top {
  width: 100%;
  z-index: 200;
  background: var(--white);
  border-bottom: 1px solid var(--medium-grey);
  opacity: 0;
  border-radius: 0 0 20px 20px;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 0;
  transition: transform .5s ease-in-out, opacity .5s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(-100%);
}

.popup_test {
  width: 100%;
  z-index: 200;
  background: var(--white);
  opacity: 0;
  border-radius: 20px 20px 0 0;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  padding: 0 0 25px;
  transition: transform .5s ease-in-out, opacity .5s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
}

.popup_top.show {
  opacity: 1;
  transform: translateY(0);
}

.popup_test_links {
  width: 100%;
}

.popup_test_link {
  font-size: var(--subtitle);
  border-bottom: 1px solid var(--medium-grey);
  color: var(--black);
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 20px 10px;
  text-decoration: none;
  display: flex;
}

.popup_test_link.cancel {
  color: var(--dark-red);
  border-bottom: none;
  margin-top: 10px;
  font-weight: bold;
}

.popup_test_link img {
  width: 20px;
}

.popup_test_link .check_saved {
  display: none;
}

.popup_test_link.saved .saved {
  display: block;
}

.popup_test_link.saved .no_saved {
  display: none;
}

.popup_test_link .no_saved {
  display: block;
}

.profile_links {
  width: 100%;
}

.profile_link {
  border-bottom: 1px solid var(--medium-grey);
  font-size: var(--subtitle);
  color: var(--black);
  gap: 30px;
  padding: 30px 40px;
  text-decoration: none;
  display: flex;
}

.profile_link img {
  width: 20px;
}

.profile_link:last-child {
  border-bottom: none;
}

.popup_close {
  position: absolute;
  top: 25px;
  right: 25px;
}

.popup_close svg {
  width: 24px;
  height: 24px;
  fill: var(--dark-blue);
}

.popup_bottom h1 {
  font-weight: 400;
}

.popup_bottom_text {
  margin: 20px 0 10px;
  font-weight: 400;
}

#popup_streak .popup_bottom_text {
  margin-top: 10px;
}

.popup_bottom.show, .popup_test.show {
  opacity: 1;
  transform: translateY(0);
}

.popup_bottom_img {
  width: 15%;
}

.ads_coins {
  padding-left: 10px;
}

.popup_bottom .btn_default {
  width: 100%;
  text-transform: none;
}

.popup_streak_data {
  margin-top: -15px;
  font-weight: 400;
}

.popup_bottom_body {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.streak_week {
  width: 100%;
  gap: 5px;
  display: flex;
}

.week_day {
  font-size: var(--subtitle);
  color: var(--dark-blue);
  border: 1px solid var(--dark-blue);
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.week_day.done {
  background: var(--dark-blue);
  color: var(--white);
}

#alert_popup .popup_close {
  position: static;
}

#alert_popup.error {
  border-color: var(--dark-red);
  background: var(--dark-red);
  color: #fff;
  border-width: 2px;
}

.alert_msg {
  font-size: var(--subtitle);
  text-align: center;
}

#alert_popup.error .btn_white {
  background: var(--light-red);
  color: var(--black);
  border: none;
}

.alert_background {
  z-index: 200;
  width: 100%;
  height: 100%;
  background: #0003;
  position: fixed;
  top: 0;
  left: 0;
}

.alert_popup_answers {
  width: 100%;
  justify-content: space-around;
  gap: 10px;
  display: flex;
}

#capture_picture {
  width: 100%;
  height: 100%;
  z-index: 300;
  transform-origin: 0 100%;
  aborder: 2px solid var(--dark-grey);
  transition: all 1s;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 12px #000000bf;
}

#capture_picture.close {
  atransform: scale(0);
  animation: capture_animation 1s ease-in-out forwards;
}

@keyframes capture_animation {
  0% {
    opacity: 1;
    transform: scale(1)translate(0);
  }

  80% {
    opacity: 1;
    transform: scale(.2)translate(10vw, -10vh);
  }

  100% {
    opacity: 0;
    transform: scale(.2)translate(10vw, -10vh);
  }
}

.capture_image, #capture_picture:after {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

#capture_picture:after {
  background: var(--white);
  content: "";
  opacity: 1;
  transition: opacity .5s;
  display: block;
}

#capture_picture.show {
  justify-content: center;
  align-items: center;
  display: flex;
}

#capture_picture.reveal:after {
  opacity: 0;
}

.progress_section {
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
}

.progress_title {
  font-size: var(--title);
  margin-bottom: 20px;
  font-weight: bold;
}

.progress_empty {
  font-weight: 400;
  font-size: var(--title);
  text-align: center;
  margin: 40px 0 20px;
}

.progress_content {
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
}

.progress_time img {
  width: 70%;
  margin-left: auto;
}

.progress_time_value {
  font-size: 50px;
  font-weight: 600;
  line-height: 1;
}

.progress_position {
  width: 66%;
}

.points_grid {
  flex-wrap: wrap;
  align-items: end;
  gap: 10px;
}

.points_item {
  text-align: center;
  width: calc(33.33% - 10px);
  margin-bottom: 20px;
}

.points_title {
  font-size: var(--title);
  margin-bottom: 10px;
  line-height: 1.2;
}

.points_result {
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
  display: flex;
}

.points_result img {
  width: 18px;
  height: 18px;
}

.points_result_answers {
  font-size: var(--title);
  font-weight: 600;
}

.progress_position {
  position: relative;
}

.progress_needle {
  height: 100%;
  transform-origin: 50% 95%;
  align-items: end;
  transition: transform 1s ease-in-out;
  display: flex;
  position: absolute;
  bottom: -5px;
  left: 45%;
  transform: rotate(0deg);
}

.needle {
  width: 100%;
  height: 90%;
}

.gauge_container {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  position: relative;
}

.gauge {
  transform: rotate(-90deg);
}

.gauge circle {
  fill: none;
  stroke-width: 10px;
}

.gauge .bg_circle {
  stroke: var(--light-blue);
}

.gauge .progress_circle {
  stroke: var(--dark-blue);
  stroke-linecap: round;
  stroke-dasharray: 0 100;
  transition: stroke-dasharray 1s;
}

.gauge_label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ranking_list {
  border: 2px solid var(--dark-blue);
  width: 100%;
  height: 100%;
  font-size: var(--subtitle);
  border-radius: 20px;
  flex-direction: column;
  margin: 30px 0 20px;
  display: flex;
  overflow: hidden;
}

.ranking_item {
  flex: 1;
  align-items: center;
  gap: 40px;
  padding: 20px 30px;
  display: flex;
}

.ranking_item:first-child {
  background: var(--light-blue);
  color: var(--dark-blue);
  font-weight: 600;
}

.ranking_name {
  flex: 1;
}

.ranking_avatar {
  width: 50px;
  height: 50px;
}

.profile_avatar {
  width: 30%;
  color: var(--black);
  text-align: center;
  font-size: var(--subtitle);
  margin: 10px 0 20px;
  text-decoration: none;
}

.profile_avatar img {
  margin-bottom: 10px;
}

.profile_field {
  border: 1px solid var(--dark-blue);
  width: 100%;
  border-radius: 10px;
  gap: 20px;
  padding: 10px 20px;
  display: flex;
}

.profile_field img {
  width: 20px;
  aheight: 20px;
  object-fit: contain;
}

.profile_field input {
  width: 100%;
  font-size: var(--title);
  border: none;
  outline: none;
}

.avatar_grid {
  flex-wrap: wrap;
  padding-top: 10px;
  display: flex;
}

.avatar_item {
  width: 33.33%;
  padding: 10px 5px 20px;
}

.avatar_item_content {
  padding: 8px 4px;
}

.avatar_item.selected .avatar_item_content {
  border: 2px solid var(--dark-blue);
  border-radius: 15px;
}

.avatar_buy {
  width: 100%;
  text-align: center;
}

@media (max-width: 820px) {
  body {
    background-color: #fff;
  }

  .app-content {
    width: 100%;
    height: 100% !important;
    transform: none !important;
  }
}

/*# sourceMappingURL=index.bf55fa9b.css.map */
